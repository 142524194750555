/* ==== меню ==== */
.menu {
	background: $c-dark;
	.mainmenu {
		display: flex;
		justify-content: center;
		margin: 0;
		&__item {
			display: inline-block;
			padding: 10px 40px;
			@include font($exo, 800, 1.6rem);
			.menu__link {
				color: white;
				text-decoration: none;
				&:hover {
					color: $c-light; } } } }
	.submenu {
		padding: 0;
		&__item {
			@include font($exo, 400, 1.3rem);
			list-style-type: none;
			padding: 1rem;
			&:hover {
				background: $c-light; }
			&:hover a {
				color: $c-dark;
				font-weight: 800; } }
		&__link {
			color: white;
			text-decoration: none; } } }

/* ==== выпадающее подменю ==== */
.dropdown {
	position: relative;
	&:hover .dropdown__content {
		display: block;
		width: 400px; }
	&__content {
		display: none;
		position: absolute;
		top: 50px;
		border-radius: 0 0 1rem 1rem;
		background: rgba($c-dark, 0.9);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
		z-index: 2; } }

@media screen and (min-width: 768px) and (max-width: 1169px) {
	.menu {
		.mainmenu {
			&__item {
				padding: 10px 20px;
				@include font($exo, 800, 1.6rem); } } }
	.submenu {
		&__item {
			@include font($exo, 400, 1rem); } } }

@media screen and (max-width: 767px) {
	.menu {
		.mainmenu {
			flex-wrap: wrap;
			&__item {
				padding: 5px 10px;
				@include font($exo, 800, 1rem); } } } }
