@charset 'UTF-8';
@import url('https://fonts.googleapis.com/css?family=Exo+2:400,400i,500,500i,600,700,800,900&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,500,700,800,900&subset=cyrillic');

$alegreya: 'Alegreya Sans SC', sans-serif;
$exo: 'Exo 2', sans-serif;

$c-light: #e6ccff;
$c-dark: #330066;

@mixin font($family, $weight, $size) {
	font-family: $family;
	font-weight: $weight;
	font-size: $size; }

* {
	box-sizing: border-box; }

body {
	@include font($exo, 400, 16px);
	background: url("../img/bg.jpg");
	margin: 0; }

ul {
	padding-left: 0; }

.container {
	max-width: 1170px;
	width: 100%;
	padding: 0 1rem;
	margin: 0 auto; }

@media screen and (min-width: 768px) and (max-width: 1169px) {
	body {
		@include font($exo, 400, 12px); } }

@media screen and (max-width: 767px) {
	body {
		@include font($exo, 400, 10px); } }
