@import "vars";
@import "menu";
@import "video";
@import "web";

.logo {
	@include font($exo, 900, 3rem);
	color: white;
	text-decoration: none;
	display: block;
	width: 390px;
	margin-top: 1rem;
	filter: drop-shadow(0 0 3px rgba(51, 0, 102, 0.5));
	&:hover {
		filter: drop-shadow(0 0 10px rgba(0, 0, 255, 0.5)); } }

.my-photo {
	display: block;
	width: 250px;
	margin: 0 auto;
	border-radius: 0 125px 125px 125px;
	border: 10px solid white; }

// форма обратной связи
#contact {
	width: 500px;
	margin: 3rem auto;
	padding: 10px;
	background-color: white;
	border-radius: 10px; }

input, textarea {
	outline: none;
	border: none;
	border-bottom: 2px solid $c-light;
	width: 100%;
	padding: 10px 5px;
	&::placeholder {
		@include font($exo, 400, 1.2rem); } }

button {
	display: block;
	width: 300px;
	border: 0;
	border-radius: 1rem;
	margin: 2rem auto;
	padding: 1rem;
	background-color: $c-dark;
	font-size: 1.5rem;
	color: $c-light;
	text-transform: uppercase;
	&:hover {
		color: white;
		filter: drop-shadow(0 0 10px rgba(0, 153, 0, 0.5)); } }

// контент
h1 {
	color: $c-dark; }

.web-link {
	a {
		text-decoration: none;
		color: $c-dark; } }

.page {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px; }

.print {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	.print__item {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		background-color: #ffffff; } }

article {
	object-fit: cover;
	overflow: hidden;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
	&:hover {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.6); } }

// контакты
.social ul {
	display: flex;
	justify-content: center;
	li {
		list-style-type: none;
		a img {
			height: 50px;
			margin: 10px;
			opacity: 0.4;
			transition: all 0.2s ease-out;
			&:hover {
				opacity: 1;
				transform: scale(2) rotate(25deg) translate(-8px, -18px);
				transition: all 0.2s ease-in; } } } }
.vhs {
	text-align: center;
	img {
		border-radius: 125px; } }
.copyright {
	background-color: $c-dark;
	color: white; }

.page-container {
	position: relative;
	min-height: 100vh; }

.content-wrap {
	padding-bottom: 9rem; }

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 9rem; }

@media screen and (min-width: 320px) and (max-width: 768px) {
	.logo {
		font-size: 2.5rem; }
	.print {
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px; }
	.vhs {
		img {
			width: 100%;
			border-radius: 50px; } }
	#contact {
		width: 90%;
		margin: 2rem auto;
		padding: 1rem;
		border-radius: 1rem; }
	button {
		width: 40%;
		border-radius: 1rem;
		margin: 2rem auto;
		padding: 1rem; } }

@media screen and (max-width: 319px) {
	.logo {
		font-size: 2rem; }
	.print {
		grid-template-columns: 1fr;
		grid-gap: 5px; }
	.vhs {
		img {
			width: 100%;
			border-radius: 25px; } }
	#contact {
		width: 90%;
		margin: 2rem auto;
		padding: 1rem;
		border-radius: 1rem; } }

@media screen and (max-width: 560px) {
	.page {
		grid-template-columns: 1fr;
		grid-gap: 10px; }
	button {
		width: 90%;
		border-radius: 1rem;
		margin: 1rem auto;
		padding: 1rem; } }
