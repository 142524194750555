.video {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	background-color: #000000;
	&__media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none; }
	&__button {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 0;
		width: 58px;
		height: 48px;
		border: none;
		outline: none;
		background-color: transparent;
		transform: translate(-50%, -50%);
		display: none;
		&-shape {
			fill: #212121;
			fill-opacity: 0.8; }
		&-icon {
			fill: #ffffff; } }
	&--enabled {
		cursor: pointer; }
	&--enabled &__button {
		display: block; }
	&:hover &__button-shape, &__button:focus &__button-shape {
		fill: #ff0000;
		fill-opacity: 1; } }
